.App {
  text-align: center;
  font-family: Arial, sans-serif;
}

header {
  background-color: #333;
  padding-top: 20px;
  padding-bottom: 20px;
  color: white;
}

main {
  padding: 20px;
}

section {
  margin-bottom: 40px;
}

h1 {
  font-size: 36px;
  margin: 0;
}

h2 {
  font-size: 24px;
  margin-bottom: 10px;
  color: #333;
}

h3 {
  font-size: 20px;
  margin-bottom: 5px;
  color: #333;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-align: left;
}

li {
  margin-bottom: 5px;
}

a {
  color: #333;
  text-decoration: none;
}

footer {
  background-color: #333;
  padding: 20px;
  color: white;
}

.footer-text {
  margin: 0;
}

.contact-info {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-icon {
  margin-right: 10px;
}

@media screen and (max-width: 900px) {
  h1 {
    font-size: 28px;
  }

  h2 {
    font-size: 20px;
  }

  h3 {
    font-size: 18px;
  }
}
.App {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

main {
  margin-top: 30px;
}

section {
  margin-bottom: 40px;
}

h1 {
  font-size: 36px;
  margin: 0;
  color: #fff;
}

h2 {
  font-size: 24px;
  margin-bottom: 10px;
  color: #333;
}

h3 {
  font-size: 20px;
  margin-bottom: 5px;
  color: #333;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-align: left;
}

li {
  margin-bottom: 5px;
}

a {
  color: #333;
  text-decoration: none;
}

footer {
  background-color: #333;
  padding: 20px;
  color: white;
}

.footer-text {
  margin: 0;
}

.contact-info {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.contact-icon {
  margin-right: 10px;
  color: #fff;
  font-size: 18px;
}

ul.about-me-list {
  list-style: square outside none;
  padding:10px 20px 10px 30px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 1.2rem;
  line-height: 1.5;

}

ul.about-me-list li {
  padding-bottom: 10px;
  left: 30px;
}

ul.about-me-list li span {
  position: relative;
  left: 20px;
}

.summary {
  font-family: "" ;
  font-weight: 500;
  font-style: italic;
  font-size: 20px;
  line-height:140%;
}

.summary p {
  line-height: 150%;
}

@media screen and (max-width: 768px) {
  h1 {
    font-size: 28px;
  }

  h2 {
    font-size: 20px;
  }

  h3 {
    font-size: 18px;
  }
}

.tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.tab {
  padding: 10px 20px;
  border: none;
  background-color: transparent;
  color: #333;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: color 0.3s;
}

.tab.active {
  color: #fff;
  background-color: #333;
  border-radius: 5px;
}

@media screen and (max-width: 768px) {
  .tab {
    font-size: 14px;
  }
}

/* Responsive styles */
@media screen and (max-width: 768px) {
  /* Adjust header styles for smaller screens */
  header {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .profile-photo {
    margin-top: 20px;
  }

  h1 {
    margin-top: 10px;
    font-size: 24px;
  }

  /* Adjust tab styles for smaller screens */
  .tabs {
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .tab {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 480px) {
  /* Adjust font size for smaller screens */
  h1 {
    font-size: 20px;
  }
}
